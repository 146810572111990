import React, { useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    TextField,
    Button,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import logo from './logo.png'; // Replace with the correct path to your logo file
import vaccineImage from './vaccine-injection-for-children.svg'; // Replace with the correct path to your image
import certificateImage from './medical-consultation.svg';
import psuedoWatch from './stop.svg';
import appointments from './man-making-business-schedule.svg';
import monitoring from './monitoring-feature.svg';
import CPA from './family-life-insurance.svg';
import DragatronServices from './services';
import GooglePlacesInput from './GooglePlacesInput';

const endpoint = 'https://mpgbfrzmlvaj5ls7bsvzyofpdu.appsync-api.ap-southeast-2.amazonaws.com/graphql';
const apiKey = 'da2-gp257yveajafhhrjev24bfbdli';

const DragatronService = ({ id, title, details, imageSrc }) => {
    const midPoint = Math.ceil(details.length / 2);
    const leftColumn = details.slice(0, midPoint);
    const rightColumn = details.slice(midPoint);

    return (
        <div className="dragatron-service">
            <div className="service-id">{id}</div>
            <div className="service-content">
                <h2 className="service-title">{title}</h2>
                <div className="service-details-container">
                    <ul className="service-details">
                        {leftColumn.map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>
                    <ul class="service-details">
                        {rightColumn.map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="service-image">
                <img src={imageSrc} alt={title} />
            </div>
        </div>
    );
};

const FormLayout = () => {
    const { control, handleSubmit, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const trackEvent = (action, category, label) => {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
        });
    };

    const onSubmit = async (data) => {
        setLoading(true);
        const mutation = `
            mutation MyMutation {
                createQuestionairre(input: {
                    Comments: "${data.Comments}",
                    ContactName: "${data.ContactName}",
                    PharmacyAddress: "${data.PharmacyAddress}",
                    PharmacyName: "${data.PharmacyName}",
                    VaccinesPerMonth: "${data.VaccinesPerMonth}",
                    email: "${data.email}",
                    pharmacistsEmployed: ${data.pharmacistsEmployed},
                    phoneNumber: "${data.phoneNumber}"
                }) {
                    Comments
                    ContactName
                    PharmacyAddress
                    PharmacyName
                    email
                    VaccinesPerMonth
                    id
                    phoneNumber
                    pharmacistsEmployed
                }
            }
        `;

        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apiKey
            },
            body: JSON.stringify({ query: mutation })
        });

        const result = await response.json();
        setLoading(false);

        if (result.data) {
            setSubmitted(true);
            trackEvent('submit', 'Form', 'Expression of Interest');
        } else {
            console.error('Submission failed:', result);
        }
    };

    if (submitted) {
        return (
            <div style={{
                backgroundColor: '#e0f7fa', // Light blue background color
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center'
            }}>
                <Typography variant="h4" gutterBottom>
                    Thank you for submitting Expression of Interest with Dragatron.<br />
                    We'll get back to you shortly.
                </Typography>
            </div>
        );
    }

    return (
        <div style={{
            backgroundColor: '#e0f7fa', // Light blue background color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 0,
            width: '100%',
        }}>
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={0} sx={{ height: '100%' }}>
                <Grid item xs={12} md={8} sx={{ padding: 1 }}>
                    <DragatronServices />
                </Grid>
                <Grid item xs={12} md={4} sx={{ padding: 1 }}>
                    <RightColumn onSubmit={handleSubmit(onSubmit)} control={control} setValue={setValue} />
                </Grid>
            </Grid>
        </div>
    );
};

const RightColumn = ({ onSubmit, control, setValue }) => {
    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent>
                <Logo />
                <FormWidget onSubmit={onSubmit} control={control} setValue={setValue} />
            </CardContent>
        </Card>
    );
};

const Logo = () => {
    return (
        <Grid container justifyContent="center" sx={{ marginBottom: 2, marginTop: -4 }}>
            <img src={logo} alt="Company Logo" style={{ maxHeight: '100px', maxWidth: '100%' }} />
        </Grid>
    );
};

const FormWidget = ({ onSubmit, control, setValue }) => {
    return (
        <form onSubmit={onSubmit}>
            <FormFields control={control} setValue={setValue} />
            <SubmitButton />
        </form>
    );
};

const FormFields = ({ control, setValue }) => {
    const inputStyle = {
        padding: '5px',
        marginBottom: '5px',
        marginLeft: '5px',
    };

    return (
        <Grid container spacing={2}>
            <Controller
                name="PharmacyName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Name of Pharmacy"
                        type="text"
                        required
                        variant="outlined"
                        style={inputStyle}
                    />
                )}
            />
            <Controller
                name="ContactName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Contact Name"
                        type="text"
                        required
                        variant="outlined"
                        style={inputStyle}
                    />
                )}
            />
            <Controller
                name="PharmacyAddress"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <GooglePlacesInput
                        apiKey="AIzaSyAv2vANIvhPQU6wIOtn54KvfiQ8zFfCYBA"
                        onPlaceSelected={(place) => {
                            if (place) {
                                const formattedAddress = place.formatted_address;
                                field.onChange(formattedAddress);
                            }
                        }}
                        inputStyle={inputStyle}
                    />
                )}
            />
            <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Email"
                        type="email"
                        required
                        variant="outlined"
                        style={inputStyle}
                    />
                )}
            />
            <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Phone"
                        type="tel"
                        required
                        variant="outlined"
                        style={inputStyle}
                    />
                )}
            />
            <Controller
                name="pharmacistsEmployed"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="Number of Pharmacists Employed"
                        type="text"
                        variant="outlined"
                        style={inputStyle}
                    />
                )}
            />
            <VaccineAdministerField control={control} setValue={setValue} />
            <Controller
                name="Comments"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        label="What improvements/capabilities would you like in the new platform"
                        multiline
                        rows={3}
                        variant="outlined"
                        required
                        style={inputStyle}
                        className="improvements-input"

                    />

                )}
            />
        </Grid>
    );
};

const VaccineAdministerField = ({ control, setValue }) => {
    const [vaccineAdminister, setVaccineAdministerState] = useState('');
    const vaccineOptions = ['Below 20', '20 - 50', '50+'];

    return (
        <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" style={{ color: 'black', textAlign: 'left' }}>How many Vaccines do you administer per month?*</FormLabel>
                <RadioGroup
                    value={vaccineAdminister}
                    onChange={(e) => {
                        setVaccineAdministerState(e.target.value);
                        setValue("VaccinesPerMonth", e.target.value);
                    }}
                >
                    {vaccineOptions.map((option) => (
                        <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                    ))}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
};

const SubmitButton = () => {
    const inputStyle = {
        padding: '5px',
        marginBottom: '5px',
        marginLeft: '-8px',
        backgroundColor: '#3d5a80',
        color: '#ffffff', // Ensure the text color is white for contrast
        '&:hover': {
            backgroundColor: '#334c6e', // Darker shade for hover state
        }
    };
    return (
        <Grid item xs={12}>
            <Button variant="contained" type="submit" fullWidth sx={inputStyle}>
                Submit
            </Button>
        </Grid>
    );
};

export default FormLayout;
