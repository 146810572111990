import React from "react";
import { TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";

const GooglePlacesInput = ({ apiKey, onPlaceSelected }) => {
  const { ref } = usePlacesWidget({
    apiKey: apiKey,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ["address"],
      componentRestrictions: { country: "au" }, // Adjust this as needed
    },
    inputAutocompleteValue: "new-password", // Helps to avoid Chrome's autocomplete suggestions
  });
  const inputStyle = {
    padding: '5px',
    marginBottom: '5px',
    marginLeft: '5px',
  };
  return (
    <TextField
      fullWidth
      color="primary"
      variant="outlined"
      inputRef={ref}
      type="text"
      label="Pharmacy Address"
      required
      style={inputStyle}

    />
  );
};

export default GooglePlacesInput;
