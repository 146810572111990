import React from 'react';
import './DragatronService.css';
import vaccineImage from './vaccine-injection-for-children.svg'; // Replace with the correct path to your image
import certificateImage from './medical-consultation.svg';
import psuedoWatch from './stop.svg'
import appointments from './man-making-business-schedule.svg'
import monitoring from './monitoring-feature.svg'
import CPA from './family-life-insurance.svg'
const DragatronService = ({ id, title, details, imageSrc }) => {
    const midPoint = Math.ceil(details.length / 2);
    const leftColumn = details.slice(0, midPoint);
    const rightColumn = details.slice(midPoint);

    return (
        <div className="dragatron-service">
            <div className="service-id">{id}</div>
            <div className="service-content">
                <h2 className="service-title">{title}</h2>
                <div className="service-details-container">
                    <ul className="service-details">
                        {leftColumn.map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>
                    <ul className="service-details">
                        {rightColumn.map((detail, index) => (
                            <li key={index}>{detail}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="service-image">
                <img src={imageSrc} alt={title} />
            </div>
        </div>
    );
};
const DragatronServices = () => {
    const services = [
        {
            id: '01',
            title: 'VACCINATIONS (NIPVIP/CVCP)',
            details: [
                'AUTOMATED AIR SUBMISSIONS',
                'COMPREHENSIVE CUSTOMER VIEW',
                'AUTOMATED CLAIM SUBMISSION WITH PPA',
                'ELECTRONIC VACCINE RECORDS',
                'NATURAL IMMUNITY & CONTRAINDICATIONS',
                'CLOUD BASED & MODERNIZED PLATFORM'
            ],
            imageSrc: vaccineImage,
        },
        {
            id: '02',
            title: 'PSEUDO-WATCH',
            details: [
                'RECORD PSEUDOEPHEDRINE SALES',
                'CLOUD BASED, SECURE PLATFORM WITH 99.99% UP-TIME',
                'INTEGRATED PRODUCT DATA FROM MAJOR WHOLESALERS',
                'COMPLIANCE WITH REGULATIONS ACROSS STATES',
                'ONE-CLICK RECORD SHARING VIA EMAIL, PRINT & CSVs'
            ],
            imageSrc: psuedoWatch,
        },
        {
            id: '03',
            title: 'CERTIFICATE OF LEAVE',
            details: [
                'PERSONAL & CARER’S LEAVE',
                'DIGITAL CERTIFICATES',
                'DIGITAL RECORDS SAVING',
                'DIGITAL SIGNATURES'
            ],
            imageSrc: certificateImage,
        },
        {
            id: '04',
            title: '8CPA & STATE PROGRAMS',
            details: [
                'STAGED SUPPLY',
                'OPIOID DEPENDENCE (ODT)',
                'MEDCHECK AND DIABETES MEDSCHECK',
                'TAKE HOME NALOXONE PROGRAM',
                'UTI SERVICE RECORDING',
                'ORAL CONTRACEPTION RECORDING'
            ],
            imageSrc: CPA,
        },
        {
            id: '05',
            title: 'RESERVATIONS',
            details: [
                'SEAMLESS RESERVATIONS WEB & IN-PLATFORM',
                'REMINDERS & UPDATES VIA EMAIL',
                'DEDICATED PHARMACY RESERVATIONS',
                'HealthDirect SERVICE FINDER'
            ],
            imageSrc: appointments,
        },
        {
            id: '06',
            title: 'HEALTH MONITORING',
            details: [
                'IRON,BLOOD PRESURE MONITORING & RECORDING',
                'BLOOD GLUCOSE MONITORING & RECORDING',
                'TAILORED PLANS AND TREATMENTS',
                'AUTOMATED FOLLOW-UPS AND REMINDERS'
            ],
            imageSrc: monitoring,
        }
    ];

    return (
        <div className="dragatron-services-container">
            {/* <h1>Expression Of Interest</h1> */}
            {services.map(service => (
                <DragatronService key={service.id} {...service} />
            ))}
        </div>
    );
};

export default DragatronServices;